<template>
  <div class="content">
    <div class="head" @click="profit = true">
      <div>
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/act/search-icon.png"
          alt=""
        />请输入您好友的用户ID
      </div>
      <div>查询</div>
    </div>
    <div class="list-head">
      <p>用户ID(昵称)</p>
      <p>任务完成奖励</p>
      <p>注册时间</p>
    </div>
    <div class="list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in list" :key="index">
            <div>
              <div>
                {{ item.userId }} <br /><span>({{ item.nickname }})</span>
              </div>
              <p>{{ item.got }}{{ item.unit }}</p>
              <p>{{ time(item.registerTime) }}</p>
            </div>
          </van-cell>
        </van-list>
    </div>
    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <div class="query">
        <p>查询邀请好友</p>
        <div class="query-sous">
          <div>
            <img
              src="https://ppyos.xijiuyou.com/imgs/invite/act/search-icon.png"
              alt=""
            />
            <input
              type="text"
              v-model="userid"
              placeholder="请输入您好友的用户ID"
            />
          </div>
          <p @click="query">查询</p>
        </div>
        <div class="titles" v-if="status==-1">尚未查询</div>
        <div class="titles" v-else-if="onelist.length==0">该用户并不是您的邀请好友</div>
        <div class="query-content" v-else>
          <div class="query-content-head">
            <p>用户ID(昵称)</p>
            <p>任务完成奖励</p>
          </div>
          <div class="query-content-list"  v-for="(item, index) in onelist" :key="index">
            <div>
              <p>{{item.userId}}</p>
              <p>{{item.nickname}}</p>
            </div>
            <p>{{ item.got }}{{ item.unit }}</p>
          </div>
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import active from "../../api/active";
import dayjs from "dayjs";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      profit: false,
      list: [],
      onelist: [],
      page: 1,
      userid: "",
      status: -1,
      loading: false,
      finished: false,
    };
  },
  methods: {
    time(item) {
      return dayjs(item).format("MM-DD HH:mm");
    },
    onLoad() {
      const that = this;
      active.myFriende(this.page).then((res) => {
        that.list.push(...res.list);
        that.loading = false;
        if (that.list.length >= res.count) {
          that.finished = true;
        } else {
          this.page++;
        }
      });
    },
    query() {
      active.myFriende(this.page, this.userid).then((res) => {
        this.onelist = res.list;
        this.status = 1;
      });
    },
    closeprofit() {
      this.profit = false;
    },
  },
};
</script>
<style scoped>
.content{
  background: #f6f6f6;
  width: 100%;
  min-height: 100vh;
}
.head {
  display: flex;
  justify-content: space-between;
  padding: 20px 14px;
  align-items: center;
  box-sizing: border-box;
}
.head div:nth-child(1) {
  height: 30px;
  background: #ffffff;
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.head div:nth-child(1) img {
  width: 16px;
  height: 20px;
  margin-right: 10px;
}
.head div:nth-child(2) {
  width: 60px;
  text-align: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.list-head {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.van-cell {
  background: #ffffff;
  padding: 0 14px;
  margin-top: 14px;
  box-sizing: border-box;
}
.van-cell div div {
  height: 62px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-cell div div div {
  width: 30%;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  display: block;
  padding-top: 12px;
}
.van-cell div div div span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.van-cell div div p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.van-cell div div p:nth-child(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.query {
  width: calc(100% - 70px);
  height: 220px;
  background: #ffffff;
  border-radius: 15px;
  margin-top: 200px;
  padding: 20px 14px;
  text-align: center;
}
.query-sous {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.query-sous div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #eeeeee;
  border-radius: 20px;
  padding-left: 13px;
}
.query-sous div img {
  width: 16px;
  height: 20px;
  margin-right: 10px;
}
.query-sous div input {
  border: 0;
  background: #eeeeee;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.query-sous p {
  width: 50px;
  text-align: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.query-content-head {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  border-bottom: 1px solid #eeeeee;
  height: 50px;
  align-items: center;
}
.query-content-list {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
}
.query-content-list div:nth-child(1) {
  text-align: left;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.query-content-list div p:nth-child(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.query-content-list p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.titles{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
}
</style>